import React, {ReactElement} from 'react'
// Component
import {Button} from '../components/atoms/button'
import {Container} from '../components/atoms/container'
import {Heading} from '../components/atoms/heading'
import {Link} from '../components/atoms/link'
// Template
import {AreYouCuriousAboutHowWeWork} from '../components/templates/are-you-curious'
// Icon
import NotFoundIcon from '../images/404-not-found.svg'

function PageNotFound(): ReactElement {
  return (
    <>
      <Container className="text-center my-20">
        <div className="flex justify-center mb-16">
          <img
            src={NotFoundIcon}
            width={500}
            alt="404 Not found @ Illustration"
          ></img>
        </div>

        <Heading component="h2">Opps, it's empty in here.</Heading>
        <div className="my-4 text-center text-purple-lightText font-semibold">
          Seems like there's nothing in this page yet.
        </div>
        <Link to="/">
          <Button>Home</Button>
        </Link>
        <div className="flex flex-row items-center justify-center mt-16"></div>
      </Container>

      <AreYouCuriousAboutHowWeWork />
    </>
  )
}

export default PageNotFound
